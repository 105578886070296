import axios from 'axios'
import router from '../../router'

// Request interceptor
axios.interceptors.request.use(
  function (config) {
    const tobeInoredUrl = [
      '/authentication/login/',
      '/programs/program-list',
      '/programs/program-detail/',
      '/authentication/register/',
      '/authentication/activate-account/',
      '/authentication/re-send-otp/',
      '/authentication/refresh/',
      '/execute',
    ]

    const isToBeIgnored = tobeInoredUrl.some((url) => config.url?.includes(url))

    if (!isToBeIgnored) {
      const token = JSON.parse(sessionStorage.getItem('access') as string)
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  function (error) {
    console.log(error)
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  function (response) {
    // Any status code that lies within the range of 2xx causes this function to trigger
    return response
  },
  function (error) {
    // Any status codes that fall outside the range of 2xx cause this function to trigger
    // do not check for wen on homepage

    if (error.response && error.response.status === 401) {
      // Avoid redirecting if already on one of the specified pages
      if (
        router.currentRoute.value.path !== '/auth/login' &&
        router.currentRoute.value.path !== '/auth/register' &&
        router.currentRoute.value.path !== '/auth/activate-account' &&
        router.currentRoute.value.path !== '/auth/re-send-otp' &&
        router.currentRoute.value.path !== '/auth/refresh' &&
        router.currentRoute.value.path !== '/'
      ) {
        router.push('/auth/login')
      }
    }
    return Promise.reject(error)
  },
)

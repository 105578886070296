import { defineStore } from 'pinia'
import makeRequest from '../services/makeRequest'

export const useProgramsStore = defineStore('programs-store', {
  state: () => {
    return {
      programs: [] as any[],
      programDetails: null as any,
      programsModuelWeeklyLessons: [] as any[],
      loadingprograms: false,
      loadingLessons: false,
      loadingProgramDetails: false,
      errorMessage: null as string | null,
      lodingEnrollToProgram: false,
      currentProgramWeekLessonStatus: null as any | null,
      selectedLesson: null as any | null,
    }
  },

  actions: {
    async fetchPrograms() {
      this.loadingprograms = true
      try {
        const url = import.meta.env.VITE_APP_API_BASE_URL + import.meta.env.VITE_APP_PROGRAM_LIST_URL
        const response = await makeRequest({
          url: url,
          method: 'get',
        })

        if (response.status === 200) {
          this.programs = response.data
        }
      } catch (error) {
        console.error('Error fetching programs:', error)
      }
    },

    async fetchUserLearningLessons(weekId: any) {
      this.loadingLessons = true
      try {
        const url =
          import.meta.env.VITE_APP_API_BASE_URL +
          import.meta.env.VITE_APP_USER_LEARNING_LESSON_LIST_URL +
          `?week_id=${weekId}`

        const response = await makeRequest({
          url: url,
          method: 'get',
        })

        if (response.status === 200) {
          this.loadingLessons = false
          this.programsModuelWeeklyLessons = response.data
        }
      } catch (error) {
        this.loadingLessons = false
        console.error('Error fetching user learning lessons:', error)
      }
    },

    async fetchProgramDetails(programId: string) {
      this.loadingProgramDetails = true
      this.errorMessage = null
      this.programDetails = null

      try {
        const url = `${import.meta.env.VITE_APP_API_BASE_URL}${
          import.meta.env.VITE_APP_PROGRAM_DETAILS_URL
        }${programId}`
        const response = await makeRequest({ url, method: 'get' })

        if (response.status === 200) {
          this.programDetails = response.data
          return response.data
        } else {
          throw new Error(`Unexpected response status: ${response.status}`)
        }
      } catch (error) {
        console.error('Error fetching program details:', error)
        this.errorMessage = 'Failed to fetch program details'
        throw error
      } finally {
        this.loadingProgramDetails = false
      }
    },

    async enrollToProgram(programId: string) {
      this.lodingEnrollToProgram = true

      const url = import.meta.env.VITE_APP_API_BASE_URL + import.meta.env.VITE_APP_USER_ENROLLED_PROGRAM_URL
      const response = await makeRequest({
        url: url,
        method: 'post',
        data: { program_id: programId },
      })
      return response
    },

    async fetchLessonStatus(lessonId: any) {
      const url =
        import.meta.env.VITE_APP_API_BASE_URL +
        import.meta.env.VITE_APP_LESSON_STATUS_URL +
        `?program_module_week_lesson_id=${lessonId}`
      const response = await makeRequest({
        url: url,
        method: 'get',
      })
      if (response.status === 200) {
        this.currentProgramWeekLessonStatus = response.data
        return response.data
      } else {
        throw new Error(`Unexpected response status: ${response.status}`)
      }
    },
    async submitLesson(lessonId: any, payload: any) {
      const url =
        import.meta.env.VITE_APP_API_BASE_URL +
        import.meta.env.VITE_APP_LESSON_STATUS_URL +
        `?program_module_week_lesson_id=${lessonId}`
      const response = await makeRequest({
        url: url,
        method: 'put',
        data: payload,
      })
      return response
    },
  },
})

import { defineStore } from 'pinia'
import makeRequest from '../services/makeRequest'
import { INavigationRoute } from '../components/sidebar/NavigationRoutes'

export const useMenuStore = defineStore('menu', {
  state: () => {
    return {
      navigationRoutes: {
        root: {
          name: '/',
          displayName: 'navigationRoutes.home',
        },
        routes: [
          {
            name: 'dashboard',
            displayName: 'menu.dashboard',
            meta: {
              icon: 'vuestic-iconset-dashboard',
            },
          },
        ] as INavigationRoute[],
      },
      loadingMenu: false,
      error: null as any,
      enrolledPrograms: [] as any,
      isEnrolledToAnyProgram: false,
    }
  },

  actions: {
    async fetchEnrolledProgramsMenu() {
      this.loadingMenu = true
      this.error = null
      const url = import.meta.env.VITE_APP_API_BASE_URL + import.meta.env.VITE_APP_ENROLLED_PROGRAMS_LIST_URL

      try {
        const response = await makeRequest({
          url: url,
          method: 'GET',
        })

        if (response.status === 200) {
          this.loadingMenu = false
          const data = response.data.modules
          this.enrolledPrograms = response.data
          this.isEnrolledToAnyProgram = data.length > 0

          const enrolledProgramsRoutes = data.map((module: any) => {
            return {
              name: module.name,
              displayName: module.display_name,
              meta: module.meta,
              children: module.children.map((week: any) => {
                return {
                  id: week.id,
                  name: week.name,
                  description: week?.description ?? 'No specified',
                  displayName: week?.display_name ?? 'No specified',
                  meta: week?.meta ?? { icon: 'vuestic-iconset-week' },
                }
              }),
            }
          }) as INavigationRoute[]

          this.navigationRoutes.routes = this.navigationRoutes.routes.filter((route) => route.name === 'dashboard')

          // Add the new fetched routes
          this.navigationRoutes.routes.push(...enrolledProgramsRoutes)

          this.loadingMenu = false
        } else {
          this.loadingMenu = false
          this.error = response.data
        }
      } catch (error: any) {
        this.loadingMenu = false
        this.error = error.message
      }
    },
  },
})

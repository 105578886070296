import { defineStore } from 'pinia'
import makeRequest from '../services/makeRequest'

export const useWeeksEnrolledProgramStore = defineStore('weeks-enrolled-program-store', {
  state: () => {
    return {
      weeksEnrolledProgram: [] as any[],
      loadingWeeksEnrolledProgram: false,
      selectedModuleWeek: {} as any,
    }
  },

  actions: {
    async setSelectedModuleWeek(weekModule: any) {
      this.selectedModuleWeek = weekModule
    },
    async fetchUserEnrolledProgram() {
      this.loadingWeeksEnrolledProgram = true
      try {
        const url = import.meta.env.VITE_APP_API_BASE_URL + import.meta.env.VITE_APP_ENROLLED_USER_PROGRAM_URL

        const response = await makeRequest({
          url: url,
          method: 'get',
        })

        if (response.status === 200) {
          this.weeksEnrolledProgram = response.data
        }
      } catch (error) {
        console.error('Error fetching weeks enrolled programs:', error)
      }
    },
  },
})

import { defineStore } from 'pinia'
import makeRequest from '../services/makeRequest'

export const useAuthStore = defineStore('user', {
  state: () => ({
    userName: 'Vasili Savitski',
    email: 'vasili@gmail.com',
    memberSince: '8/12/2020',
    pfp: 'https://picsum.photos/id/22/200/300',
    is2FAEnabled: false,
    logingin: false,
    signingup: false,
    activating: false,
    resending: false,
    userProfile: {} as any,
    isStudent: false,
    loadingProfile: false,
  }),

  actions: {
    toggle2FA() {
      this.is2FAEnabled = !this.is2FAEnabled
    },

    changeUserName(userName: string) {
      this.userName = userName
    },

    async login(payload: { username: string; password: string }) {
      this.logingin = true
      try {
        const url = import.meta.env.VITE_APP_API_BASE_URL + import.meta.env.VITE_APP_AUTH_LOGIN_URL
        const data = JSON.stringify({
          username: payload.username,
          password: payload.password,
        })

        const response = await makeRequest({
          url: url,
          method: 'post',
          headers: {},
          data: data,
        })

        this.logingin = false
        return response
      } catch (error) {
        this.logingin = false
        throw error
      }
    },

    async signup(payload: any) {
      this.signingup = true
      try {
        const url = import.meta.env.VITE_APP_API_BASE_URL + import.meta.env.VITE_APP_AUTH_SIGNUP_URL
        const data = JSON.stringify(payload)

        const response = await makeRequest({
          url: url,
          method: 'post',
          headers: {},
          data: data,
        })

        this.userName = payload.username
        this.email = payload.email
        this.memberSince = new Date().toLocaleDateString()
        this.signingup = false

        return response
      } catch (error) {
        this.signingup = false
        throw error
      }
    },

    async activateAccount(payload: { email: string; otp: string }) {
      this.activating = true
      try {
        const url = import.meta.env.VITE_APP_API_BASE_URL + import.meta.env.VITE_APP_AUTH_ACTIVATE_ACCOUNT
        const data = JSON.stringify(payload)

        const response = await makeRequest({
          url: url,
          method: 'post',
          headers: {},
          data: data,
        })

        this.activating = false
        return response
      } catch (error) {
        this.activating = false
        throw error
      }
    },

    async resendOtp(payload: { email: string }) {
      this.resending = true
      try {
        const url = import.meta.env.VITE_APP_API_BASE_URL + import.meta.env.VITE_APP_AUTH_RESEND_OTP
        const data = JSON.stringify(payload)

        const response = await makeRequest({
          url: url,
          method: 'post',
          headers: {},
          data: data,
        })

        this.resending = false
        return response
      } catch (error) {
        this.resending = false
        throw error
      }
    },

    async getUserProfile() {
      this.loadingProfile = true
      const url = import.meta.env.VITE_APP_API_BASE_URL + import.meta.env.VITE_APP_AUTH_USER_URL
      const response = await makeRequest({
        url: url,
        method: 'get',
      })
      if (response.status === 200) {
        this.loadingProfile = false
        this.userProfile = response.data
        this.isStudent = response.data.user_type === 'STUDENT'
        localStorage.setItem('userProfile', JSON.stringify(response.data))
        return response
      }
      return response
    },
  },
})

<template>
  <RouterLink to="/" aria-label="Visit home page">
    <img :src="getUrl" :style="{ width: width, height: height }" alt="Logo" class="admin_logo" />
  </RouterLink>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { RouterLink } from 'vue-router'
export default defineComponent({
  name: 'IntmainLogo',
  components: {
    RouterLink,
  },
  props: {
    width: {
      type: String,
      default: '180px',
    },
    isWhiteBg: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '40px',
    },
  },

  computed: {
    getUrl() {
      return !this.isWhiteBg ? '/images/logo/logo4.png' : '/images/logo/logo5.png'
    },
  },
})
</script>

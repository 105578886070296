<template>
  <VaSplit vertical class="h-[100vh]">
    <template #start>
      <template v-if="loadingEditor">
        <div id="loading">
          <IntmainLogo :is-white-bg="true" :height="'100'" />
          Loading editor...
        </div>
      </template>
      <template v-else-if="errorLoadingEditor">
        <div id="error">Error loading editor</div>
      </template>
      <template v-else>
        <!-- {{ selectedLanguageObject }} -->

        <div id="monaco-container">
          <div class="editor-header">
            <IntmainLogo :is-white-bg="false" :height="'35px'" :width="'100px'" />
            <!-- longuage seletor -->
            <div class="language-selector">
              <VaSelect
                v-model="selectedLanguageObject"
                :options="languages"
                class="selector my-6"
                searchable
                :outlined="false"
                :disabled="loadingEditor"
                @update:modelValue="updateLanguageCodeSnippet"
              >
                <!-- <template #option="{ option }: any">
                  <div class="flex justify-between items-center p-2">
                    <a class="language-name" href="#" @click.prevent="selectOption(option)">{{ option.text }}</a>
                    <VaChip class="mb-2" size="small">
                      {{ option.version }}
                    </VaChip>
                  </div>
                </template> -->
              </VaSelect>
            </div>
            <!-- ?rest -->
          </div>
          <div ref="editorRef" class="editor"></div>

          <div class="editor-footer">
            <div class="actions-button">
              <!-- save button -->
              <VaButton icon="save" class="mr-2" @click="console.log('save')"></VaButton>
              <!-- ?rest -->
              <VaButton icon="history" class="mr-2" @click="console.log('history')"></VaButton>
              <!-- run button -->
              <VaButton
                :loading="loadingOutput"
                :disabled="loadingEditor"
                icon="play_arrow"
                class="mr-2"
                @click="runCode"
                >run</VaButton
              >
            </div>
          </div>
        </div>
      </template>
    </template>
    <template #grabber>
      <div class="custom-grabber">
        <VaIcon name="swap_vert" />
      </div>
    </template>
    <template #end>
      <div v-if="!loadingOutput" class="output-container">
        <div id="output">
          <h2>Output</h2>
          <pre>{{ output }}</pre>
        </div>
      </div>
      <div v-else class="loading-output">
        <IntmainLogo :is-white-bg="true" :height="'100'" />
        <Loader :loading-text="'Loading output...'"></Loader>
      </div>
    </template>
  </VaSplit>
</template>
<script lang="ts">
import { defineComponent, nextTick, onMounted, reactive, ref, toRefs, watch } from 'vue'
import * as monaco from 'monaco-editor'
import { editor } from 'monaco-editor'
import IntmainLogo from '../../../components/IntmainLogo.vue'
import { CODE_SNIPPETS } from '../codeSnippets'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { usePistonStore } from '../../../stores/piston-store'
import Loader from '../../../components/Loader.vue'
import { useGlobalStore } from '../../../stores/global-store'
import { Language } from '../../../../types/models'

export default defineComponent({
  name: 'MonacoEditor',
  components: {
    IntmainLogo,
    Loader,
  },
  props: {
    selectedLesson: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const state = reactive({
      value: '',
    })

    const _usePistonStore = usePistonStore()
    const _useGlobalStore = useGlobalStore()

    const editorRef = ref()
    let monacoEditor: editor.IStandaloneCodeEditor = null as any
    const selectedLanguageObject: Language = reactive({
      value: 'javascript',
      text: 'javaScript',
      version: '1.32.3',
    })

    const creatMonacoEditor = () => {
      _useGlobalStore.isLoadingDetailPage = true
      monacoEditor = monaco.editor.create(editorRef.value, {
        value: CODE_SNIPPETS['javascript'] || '',
        language: selectedLanguageObject.value,
        theme: 'vs-dark',
      })
      monacoEditor.onDidChangeModelContent((e: any) => {
        console.log('onDidChangeModelContent', e)
        const value = monacoEditor.getValue()
        console.log(value)
      })
    }

    const getValue = () => {
      console.log('monacoEditor.value?.getValue', monacoEditor?.getValue())
    }
    watch(
      () => monacoEditor,
      (newValue, oldValue: any) => {
        console.log(oldValue)
        nextTick(() => {
          if (newValue) {
            newValue.layout()
          }
        })
      },
    )

    onMounted(() => {
      creatMonacoEditor()
      _usePistonStore.fetchRuntimes()
    })

    const updateLanguageCodeSnippet = (language: any) => {
      // set language model
      console.log('updateLanguageCodeSnippet', language)
      selectedLanguageObject.value = language.value
      selectedLanguageObject.text = language.text
      selectedLanguageObject.version = language.version

      if (!CODE_SNIPPETS[language.value] || !monacoEditor) return
      monacoEditor.setValue(CODE_SNIPPETS[language.value])
      const model = monacoEditor.getModel()
      if (model) {
        monaco.editor.setModelLanguage(model, language.value)
      }
      monacoEditor.layout()
    }

    const runCode = async () => {
      if (monacoEditor) {
        const editorValue = monacoEditor.getValue()
        console.log('Editor Value:', editorValue)
      } else {
        console.warn('Editor instance is not initialized.')
      }

      try {
        const response = await _usePistonStore.executeCode({
          language: selectedLanguageObject,
          sourceCode: monacoEditor.getValue(),
        })
        console.log(response)
      } catch (error) {
        _usePistonStore.loadingOutput = false
        console.error(error)
      }
    }

    return {
      // data
      ...toRefs(state),
      getValue,
      selectedLanguageObject,
      updateLanguageCodeSnippet,
      editorRef,
      runCode,
      _usePistonStore,
    }
  },
  data() {
    // const editorRef = ref<any>('editorRef')
    return {
      editor,
      loadingEditor: false,
      errorLoadingEditor: false,
      CODE_SNIPPETS,
      languages: [],
    }
  },

  computed: {
    ...mapState(usePistonStore, ['output', 'loadingOutput']),
    ...mapWritableState(usePistonStore, { _loadingOutput: 'loadingOutput' }),
    ...mapWritableState(useGlobalStore, { _isLoadingDetailPage: 'isLoadingDetailPage' }),
  },
  mounted() {
    // ✅ Initialize Monaco Editor
    // this.loadEditor()
    this.getLaguages()

    this._isLoadingDetailPage = false
  },
  methods: {
    ...mapActions(usePistonStore, ['executeCode', 'fetchRuntimes']),

    selectOption(option: any) {
      this.selectedLanguageObject = option
    },

    async getLaguages() {
      try {
        const response = await this.fetchRuntimes()
        console.log(response)
        this.languages = response.data.map((runtime: any) => {
          return {
            value: runtime.language,
            text: runtime.language,
            version: runtime.version,
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
  },
})
</script>

<style scoped lang="scss">
.split-demo {
  & .custom-grabber {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--va-background-element);
  }
}
#monaco-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.editor-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #333;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}
.editor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  background-color: #333;
  color: #fff;
  font-weight: bold;
}
.language-selector {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.selector {
  --va-select-border-color: #333;
  --va-select-border-radius: 0;
}

#output {
  height: 100vh;
  width: 100%;
  display: wrap;
  flex-direction: column;
  justify-content: start;
  padding: 20px;

  align-items: start;
  font-size: 18px;
  color: #fff;
  background-color: #333;
}
.loading-output {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.editor {
  height: 100vh;
  width: 100vw;
}

#loading {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 24px;
  color: #999;
}
a.language-name {
  color: #000;
  text-decoration: none;
}

.actions-button {
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  margin-right: 20px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#error {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: #f00;
}

.loading-editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  justify-content: center;
  text-align: center;
}
</style>

export const CODE_SNIPPETS = {
  matl: `
    'Hello, Alex!'
    disp
    `,
  bash: `
  echo "Hello, Alex!"
    `,
  befunge93: `
  v"Hello, Alex!"
  @  `,
  bqn: `
  "Hello, Alex!"
    `,
  brachylog: `
  "Hello, Alex!"
  w.  `,
  brainfuck: `
  >+++++++++[<++++++++>-]<++.>+++++++[<+++++++>-]<+.+++++++..+++.>++++++++[<++++>-]<++.<++++++++.+++++++++++.>+++.<+++++++.------.--------.>+.>.
    `,
  cjam: `
  "Hello, Alex!" o
    `,
  clojure: `
  (println "Hello, Alex!")
    `,
  cobol: `
  IDENTIFICATION DIVISION.
  PROGRAM-ID. HELLO.
  PROCEDURE DIVISION.
     DISPLAY "Hello, Alex!".
     STOP RUN.
    `,
  coffeescript: `
  console.log "Hello, Alex!"
    `,
  crystal: `
  puts "Hello, Alex!"
    `,
  dart: `
  void main() {
    print("Hello, Alex!");
  }
    `,
  typescript: `
  type Params = {
    name: string;
  };
  
  function greet(data: Params) {
    console.log("Hello, " + data.name + "!");
  }
  
  greet({ name: "Alex" });
    `,
  c: `
  #include <stdio.h>
  
  int main() {
      printf("Hello, Alex!\n");
      return 0;
  }
    `,
  cpp: `
  #include <iostream>
  
  void greet(std::string name) {
      std::cout << "Hello, " << name << "!" << std::endl;
  }
  
  int main() {
      greet("Alex");
      return 0;
  }
    `,
  go: `
  package main
  
  import "fmt"
  
  func greet(name string) {
      fmt.Println("Hello,", name + "!")
  }
  
  func main() {
      greet("Alex")
  }
    `,

  javascript: `
  function greet(name) {
      console.log("Hello, " + name + "!");
  }
  
  greet("Alex");
    `,
  julia: `
  println("Hello, Alex!")
    `,
  kotlin: `
  fun greet(name: String) {
      println("Hello, $name!")
  }
  
  greet("Alex")
    `,
  lua: `
  print("Hello, Alex!")
    `,
  mathematica: `
  Print["Hello, Alex!"]
    `,
  matlab: `
  disp("Hello, Alex!")
    `,
  nim: `
  echo "Hello, Alex!"
    `,
  objectivec: `
  #import <Foundation/Foundation.h>
  
  int main(int argc, const char * argv[]) {
      @autoreleasepool {
          NSLog(@"Hello, Alex!");
      }
      return 0;
  }
  `,

  java: `
  public class HelloWorld {
      public static void main(String[] args) {
          System.out.println("Hello, Alex!");
      }
  }
    `,
  python: `
  def greet(name):
      print("Hello, " + name + "!")
  
  greet("Alex")
    `,
  ruby: `
  def greet(name)
    puts "Hello, #{name}!"
  end
  
  greet("Alex")
    `,
  rust: `
  fn greet(name: &str) {
      println!("Hello, {}!", name);
  }
  
  fn main() {
      greet("Alex");
  }
    `,
  swift: `
  func greet(name: String) {
      print("Hello, (name)!")
  }
  
  greet(name: "Alex")
    `,
  zig: `
  const std = @import("std");
  
  pub fn main() void {
      std.debug.print("Hello, Alex!\n", .{});
  }
    `,
  sql: `
  SELECT 'Hello, Alex!' AS message;
    `,
  sqlite3: `
  SELECT 'Hello, Alex!' AS message;
    `,
} as any

<template>
  <div v-if="isLoading" class="loader">
    <IntmainLogo :is-white-bg="true" :height="'100'" />
    <Loader></Loader>
  </div>
  <RouterView v-else />
</template>
<script lang="ts">
import { mapActions } from 'pinia'
import { defineComponent, onMounted, ref } from 'vue'
import { RouterView } from 'vue-router'
import { useMenuStore } from './stores/menu-store'
import IntmainLogo from './components/IntmainLogo.vue'
import Loader from './components/Loader.vue'

export default defineComponent({
  name: 'App',
  components: {
    RouterView,
    IntmainLogo,
    Loader,
  },
  setup() {
    const isLoading = ref(true)

    onMounted(() => {
      setTimeout(() => {
        isLoading.value = false
      }, 2000)
    })

    return {
      isLoading,
    }
  },
  data() {
    return {}
  },

  mounted() {},
  methods: {
    ...mapActions(useMenuStore, ['fetchEnrolledProgramsMenu']),
  },
})
</script>

<style lang="scss">
#app {
  font-family: 'Inter', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000; // Ensure the loader is above other content
}
</style>

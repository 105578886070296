<template>
  <div class="spinner"></div>
  <div class="loader-text">
    <p>{{ loadingText }}</p>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Loader',

  components: {},
  props: {
    loadingText: {
      type: String,
      default: 'Please wait while the page is loading.',
    },
  },
  setup() {},
})
</script>

<style lang="scss">
#app {
  font-family: 'Inter', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000; // Ensure the loader is above other content
}

.spinner {
  width: 4rem;
  height: 4rem;
  border: 5px solid rgb(255, 174, 0);
  border-top: 5px solid #0a2540;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

.loader-text {
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<template>
  <div>
    <VaLayout v-if="breakpoint.lgUp" class="h-screen bg-[var(--va-background-secondary)]">
      <template #left>
        <div class="link-container">
          <MonacoEditor :selected-lesson="{}"></MonacoEditor>
        </div>
      </template>
      <template #content>
        <main class="h-full flex flex-col items-center justify-center mx-auto max-w-[460px]">
          <IntmainLogo
            :height="'100px'"
            width="300px"
            class="flex items-center justify-center mx-auto max-w-[460px]"
            :is-white-bg="true"
          />
          <p class="text-center text-sm text-gray-500 text-lg mt-4">
            Log in to your account to access your dashboard and enroll in programs.
          </p>
          <RouterView />
        </main>
      </template>
    </VaLayout>
    <!-- Rest unchanged -->
    <VaLayout v-else class="h-screen bg-[var(--va-background-secondary)]">
      <template #content>
        <div class="p-4">
          <main class="h-full flex flex-row items-center justify-start mx-auto max-w-[420px]">
            <div class="flex flex-col items-start">
              <RouterLink class="py-4" to="/" aria-label="Visit homepage"> </RouterLink>
              <RouterView />
            </div>
          </main>
        </div>
      </template>
    </VaLayout>
  </div>
</template>

<script lang="ts" setup>
import { useBreakpoint } from 'vuestic-ui'
import MonacoEditor from '../pages/Lessons/components/MonacoEditor.vue'
import IntmainLogo from '../components/IntmainLogo.vue'

const breakpoint = useBreakpoint()
</script>

<style scoped>
.link-container {
  position: relative;
  width: 58vw;
  height: 100%;
}

.background-image {
  position: absolute;
  inset: 0;
  background-size: cover;
  background-position: center;
  background-image: url(/images/stand_tech_woman.jpg);
  filter: brightness(0.9);
  transform: scale(1);
  transition: transform 0.3s ease;
}

.link-container:hover .background-image {
  transform: scale(1.05);
}

.blur-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom, rgba(17, 24, 39, 0.5), transparent);
  backdrop-filter: blur(4px);
}
</style>

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'

const routes: Array<RouteRecordRaw> = [
  {
    name: 'home',
    path: '/',
    component: () => import('../layouts/WebLayout.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'privacy',
    path: '/privacy',
    component: () => import('../components/web-layout/legal/LegalPage.vue'),
    props: { pageId: 'privacy_policy' },
    meta: { requiresAuth: false },
  },
  {
    name: 'terms-of-service',
    path: '/terms-of-service',
    component: () => import('../components/web-layout/legal/LegalPage.vue'),
    props: { pageId: 'terms_of_service' },
    meta: { requiresAuth: false },
  },
  {
    name: 'about-us',
    path: '/about-us',
    component: () => import('../components/web-layout/about_us/WebAboutUs.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'program-details',
    path: '/program/:id',
    component: () => import('../components/web-layout/programs/ProgramDetails.vue'),
    props: true,
    meta: { requiresAuth: false },
  },

  {
    name: 'donation',
    path: '/donation',

    component: () => import('../components/web-layout/donation/DonationPage.vue'),
    meta: { requiresAuth: false },
  },

  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'activate-account',
        path: 'activate-account',
        component: () => import('../pages/auth/ActivateAccount.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '/auth',
        redirect: { name: 'login' },
      },
    ],
  },

  {
    name: 'app',
    path: '/app',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/users/UsersPage.vue'),
      },

      {
        name: 'weekly-lessons',
        path: 'weekly-lessons',
        component: () => import('../pages/Lessons/LessonsPage.vue'),
      },

      {
        name: 'program-module',
        path: 'program-module',
        component: () => import('../pages/program-module/ProgramModulePage.vue'),
      },
      {
        name: 'lesson-details',
        path: 'lesson-details',
        component: () => import('../pages/Lessons/components/LessonDetails.vue'),
        props: true,
      },

      {
        name: 'projects',
        path: 'projects',
        component: () => import('../pages/projects/ProjectsPage.vue'),
      },
      {
        name: 'payments',
        path: '/payments',
        component: RouteViewComponent,
        children: [
          {
            name: 'payment-methods',
            path: 'payment-methods',
            component: () => import('../pages/payments/PaymentsPage.vue'),
          },
          {
            name: 'billing',
            path: 'billing',
            component: () => import('../pages/billing/BillingPage.vue'),
          },
          {
            name: 'pricing-plans',
            path: 'pricing-plans',
            component: () => import('../pages/pricing-plans/PricingPlans.vue'),
          },
        ],
      },
      {
        name: 'faq',
        path: '/faq',
        component: () => import('../pages/faq/FaqPage.vue'),
      },
      {
        path: '/tutor-programs',
        name: 'tutor-programs',
        component: () => import('../pages/admin/tutordashboard/programs/TutorProgram.vue'),
      },
      {
        path: '/tutor-module-to-program',
        name: 'tutor-module-to-program',
        component: () => import('../pages/admin/tutordashboard/programs/TutorModuleToProgram.vue'),
      },
      {
        path: '/tutor-sub-module',
        name: 'tutor-sub-module',
        component: () => import('../pages/admin/tutordashboard/programs/TutorSubModule.vue'),
      },
      {
        path: '/tutor-lesson-to-sub-module',
        name: 'tutor-lesson-to-sub-module',
        component: () => import('../pages/admin/tutordashboard/programs/TutorLessonToSubModule.vue'),
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },

  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'home' },
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

export default router

import axios from 'axios'
import IRequestParams from '../../types/models'

async function makeRequest(params: IRequestParams) {
  const config = {
    method: params.method,
    maxBodyLength: Infinity,
    url: params.url,
    headers: {
      'Content-Type': 'application/json',
      ...params.headers,
    },
    data: params.data,
  }

  const response = await axios.request(config)
  return response
}

export default makeRequest

<template>
  <!-- v-model="writableVisible" :width="sidebarWidth" -->
  <template v-if="_loadingMenu">
    <div class="loading-menu">
      <!-- <IntmainLogo :is-white-bg="true" /> -->
      <Loader :loading-text="'Loading menu...'"></Loader>
    </div>
  </template>
  <template v-else>
    <VaSidebar v-model="writableVisible" :width="sidebarWidth" :color="color" minimized-width="43px">
      <!-- <template v-if="!hasEnrolledPrograms ">
        <div class="no-enrolled-programs">
          <h2>No enrolled programs</h2>
          <p>You are not enrolled in any program yet. Please enroll in a program to access the lessons.</p>
          <VaButton to="/programs" color="primary" outlined>Enroll in a program</VaButton>
        </div>
      </template> -->
      <!-- <template v-else> -->
      <VaAccordion v-model="value" multiple>
        <VaCollapse v-for="(route, index) in navigationRoutes.routes" :key="index">
          <template #header="{ value: isCollapsed }">
            <VaSidebarItem
              :to="route.children ? undefined : { name: route.name }"
              :active="routeHasActiveChild(route)"
              :active-color="activeColor"
              :text-color="textColor(route)"
              :aria-label="`${route.children ? 'Open category ' : 'Visit'} ${t(route.displayName)}`"
              role="button"
              hover-opacity="0.10"
              @click="_isSidebarMinimized = false"
            >
              <VaSidebarItemContent class="py-3 pr-2 pl-4">
                <VaIcon v-if="route.meta.icon" aria-hidden="true" :name="route.meta.icon" :color="iconColor(route)" />
                <VaSidebarItemTitle class="flex justify-between items-center leading-5 font-semibold">
                  {{ t(route.displayName) }}
                  <VaIcon v-if="route.children" :name="arrowDirection(isCollapsed)" size="20px" />
                </VaSidebarItemTitle>
              </VaSidebarItemContent>
            </VaSidebarItem>
          </template>
          <template #body>
            <div v-for="(childRoute, index2) in route.children" :key="index2">
              <VaSidebarItem
                :to="{ name: childRoute.name }"
                :active="isActiveChildRoute(childRoute)"
                :active-color="activeColor"
                :text-color="textColor(childRoute)"
                :aria-label="`Visit ${t(childRoute?.displayName)}`"
                hover-opacity="0.10"
                @click="getLessions(childRoute)"
              >
                <VaSidebarItemContent class="py-3 pr-2 pl-11">
                  <VaIcon
                    v-if="route.meta.icon"
                    aria-hidden="true"
                    :name="childRoute.meta.icon"
                    size="20px"
                    :color="iconColor(route)"
                  />
                  <VaSidebarItemTitle class="leading-5 font-semibold">
                    {{ t(childRoute?.displayName) }}
                  </VaSidebarItemTitle>
                </VaSidebarItemContent>
              </VaSidebarItem>
            </div>
          </template>
        </VaCollapse>
      </VaAccordion>
      <!-- </template> -->
      <VaSpacer />

      <VaSidebarItem :to="{ name: 'settings' }">
        <VaSidebarItemContent>
          <VaIcon name="settings" />
          <VaSidebarItemTitle>Settings</VaSidebarItemTitle>
        </VaSidebarItemContent>
      </VaSidebarItem>
    </VaSidebar>
  </template>
</template>
<script lang="ts">
import { defineComponent, watch, ref, computed } from 'vue'
import { useRoute } from 'vue-router'

import { useI18n } from 'vue-i18n'
import { useColors } from 'vuestic-ui'

import { useMenuStore } from '../../stores/menu-store'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { INavigationRoute } from './NavigationRoutes'
import localNavigationRoutes from './NavigationRoutes'
import { useProgramsStore } from '../../stores/programs-store'
import { useWeeksEnrolledProgramStore } from '../../stores/enrolled-program-week'
import { useGlobalStore } from '../../stores/global-store'
import { useAuthStore } from '../../stores/auth-store'
import Loader from '../Loader.vue'
// import IntmainLogo from '../IntmainLogo.vue'

export default defineComponent({
  name: 'Sidebar',
  components: {
    // IntmainLogo,
    Loader,
  },
  props: {
    visible: { type: Boolean, default: true },
    mobile: { type: Boolean, default: false },
  },
  emits: ['update:visible'],

  setup: (props, { emit }) => {
    const { getColor, colorToRgba } = useColors()
    const route = useRoute()
    const { t } = useI18n()
    const navigationRoutes = computed(() =>
      useAuthStore().isStudent ? useMenuStore().navigationRoutes : localNavigationRoutes,
    )

    const hasEnrolledPrograms = computed(() => useMenuStore().isEnrolledToAnyProgram)

    const value = ref<boolean[]>([])

    const writableVisible = computed({
      get: () => props.visible,
      set: (v: boolean) => emit('update:visible', v),
    })

    const isActiveChildRoute = (child: INavigationRoute) => route.name === child.name

    const routeHasActiveChild = (section: INavigationRoute) => {
      if (!section.children) {
        return route.path.endsWith(`${section.name}`)
      }

      return section.children.some(({ name }) => route.path.endsWith(`${name}`))
    }

    const setActiveExpand = () =>
      (value.value = navigationRoutes.value.routes.map((route: INavigationRoute) => routeHasActiveChild(route)))

    const sidebarWidth = computed(() => (props.mobile ? '100vw' : '280px'))
    const color = computed(() => getColor('menuBackground'))
    const activeColor = computed(() => colorToRgba(getColor('textMenuPrimary'), 0.1))

    const iconColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? 'textMenuPrimary' : 'textMenuPrimary')
    const textColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? 'textMenuPrimary' : 'textMenuPrimary')
    const arrowDirection = (state: boolean) => (state ? 'va-arrow-up' : 'va-arrow-down')

    watch(() => route.fullPath, setActiveExpand, { immediate: true })

    return {
      writableVisible,
      sidebarWidth,
      value,
      color,
      activeColor,
      navigationRoutes,
      routeHasActiveChild,
      isActiveChildRoute,
      t,
      iconColor,
      textColor,
      arrowDirection,
      hasEnrolledPrograms,
    }
  },
  computed: {
    ...mapState(useMenuStore, ['error', 'loadingMenu', 'enrolledPrograms']),
    ...mapWritableState(useMenuStore, { _loadingMenu: 'loadingMenu' }),
    ...mapWritableState(useGlobalStore, { _isSidebarMinimized: 'isSidebarMinimized' }),
    ...mapState(useAuthStore, ['userProfile']),
  },

  mounted() {
    this.fetchEnrolledProgramsMenu()
    this.getUserProfile()
  },

  methods: {
    ...mapActions(useProgramsStore, ['fetchUserLearningLessons']),
    ...mapActions(useWeeksEnrolledProgramStore, ['setSelectedModuleWeek']),
    ...mapActions(useMenuStore, ['fetchEnrolledProgramsMenu']),
    ...mapActions(useAuthStore, ['getUserProfile']),

    getLessions(childRoute: any) {
      this.fetchUserLearningLessons(childRoute.id)
      this.setSelectedModuleWeek(childRoute)
    },
  },
})
</script>

<style lang="scss" scoped>
.loading-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}
</style>

export default {
  presets: {
    light: {
      backgroundPrimary: '#F4F6F8',
      backgroundSecondary: '#F4F6F8',
      backgroundCardPrimary: '#fff',
      backgroundCardSecondary: '#fff',
      success: '#228200',
      info: '#158DE3',
      danger: '#E42222',
      warning: '#FFC300',
      primary: '#0A2540',
      menuBackground: '#fff',
      textPrimary: '#333333',
      textMenuPrimary: '#0A2540',
      textColor: '#333333',
    },
    dark: {
      backgroundCardPrimary: '#111827',
      backgroundCardSecondary: '#111827',
      textPrimary: '#fff',
      primary: '#FFC300',
      menuBackground: '#111827',
      success: '#228200',
      info: '#158DE3',
      textMenuPrimary: '#FFC300',
    },
  },
}

import { defineStore } from 'pinia'
import makeRequest from '../services/makeRequest'
import { PISTON_SUBURL } from '../services/utils'

export const usePistonStore = defineStore('piston', {
  state: () => ({
    language: '',
    sourceCode: '',
    output: null as any,
    loadingOutput: false,
    error: null,
    errorMessage: '',
    languageOptions: [],
    selectedLanguage: '',
    selectedVersion: '',
    versions: [],
    languages: [],
    selectedLanguageObject: {
      value: 'javascript',
      text: 'javascript',
      version: '1.32.3',
    },
  }),

  actions: {
    async executeCode(payload: any) {
      this.loadingOutput = true
      const response = await makeRequest({
        url: import.meta.env.VITE_APP_PISTON_API_URL + PISTON_SUBURL.EXECUTE,
        method: 'POST',
        data: {
          language: payload.language.value,
          version: payload.language.version,
          files: [
            {
              content: payload.sourceCode,
            },
          ],
        },
      })

      if (response.status === 200) {
        this.output = response.data.run.stdout || response.data.run.stderr || response.data.run.output
        this.loadingOutput = false
        this.error = null
      } else {
        this.loadingOutput = false

        this.errorMessage = response.data.message
      }
      return response
    },

    async fetchRuntimes() {
      const response = await makeRequest({
        url: import.meta.env.VITE_APP_PISTON_API_URL + PISTON_SUBURL.RUNTIMES,
        method: 'GET',
      })

      return response
    },
  },
})
